import React from 'react';
import { Flex, Stack, Text, Tooltip, Icon, Switch } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const PaidAdsToggle = ({ setIsPaidAdsActive, fontSize="17px" }) => {
  const [toggle, setToggle] = React.useState(true);

  const handleSwitchChange = (checked) => {
    setToggle(checked);
    setIsPaidAdsActive(checked);
  };

  return (
    <Flex ml={{base: 0, md: 6}}   maxW="100%" height="60px"  alignItems="center"  marginRight={{ base: "0", lg: "0" }}>
      <Stack direction="column" spacing={0} w="100%">
        <Flex justify="space-between" alignItems="center" marginBottom={1}>
          <Text color="secondaryGray.900" fontSize={fontSize} fontWeight="500" align="left">
            Paid Ads
            <Tooltip hasArrow size='sm' label='Will you run paid ad campaigns?' placement='top' closeOnClick={false} textAlign="center">
              <Icon
                as={InfoOutlineIcon}
                boxSize={4}
                ml={2}
                mb={.5}
                color="secondaryGray.800"
                _hover={{ cursor: 'pointer', color: 'blue.500' }} 
              />
            </Tooltip>
          </Text>
        </Flex>
        <Flex color="blue.500" fontSize="18px" align="center" alignItems="center" alignContent="center">
          <Switch 
            id="paid-ads" 
            size="md"
            onChange={(e) => handleSwitchChange(e.target.checked)} 
            isChecked={toggle}
          />
        </Flex>
      </Stack>
    </Flex>
  );
};

export default PaidAdsToggle;
