import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import DropShippingCalculator from "components/simple-calculator/DropShippingCalculator";
import Content from "./content";
import { Helmet } from 'react-helmet-async';

const SimpleCalculator = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Advanced Free Dropshipping Profit Calculator for In-Depth Financial Analysis",
        "description": "Discover the ultimate free profit calculator for dropshippers and e-commerce entrepreneurs. Calculate true profit margins by factoring in ad spend, taxes, returns, shipping fees, and hidden costs.",
        "url": "https://www.droplla.com/dropshipping-profit-margin-calculator",
        "keywords": "dropshipping, profit calculator, e-commerce, margin analysis, business tool",
        "image": "https://www.droplla.com/images/profit-margin-calculator-screenshot.png",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "name": "Dropshipping Profit Margin Calculator"
        },
        "creator": {
            "@type": "Organization",
            "name": "Droplla"
        },
        "mainEntity": {
            "@type": "SoftwareApplication",
            "name": "Dropshipping Profit Calculator",
            "operatingSystem": "Web-based",
            "applicationCategory": "BusinessApplication",
            "offers": {
                "@type": "Offer",
                "price": "0.00",
                "priceCurrency": "USD"
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Free Dropshipping Profit Calculator | Calculate True Margins</title>
                <meta name="description" content="Discover the ultimate free profit calculator for dropshippers. Factor in ad spend, taxes, returns, and hidden costs to uncover true profit margins with ease." />
                <link rel="canonical" href="https://www.droplla.com/dropshipping-profit-margin-calculator" />

                {/* Open Graph for social media sharing */}
                <meta property="og:title" content="Free Dropshipping Profit Calculator" />
                <meta property="og:description" content="Calculate true profit margins for your dropshipping business with our free tool." />
                <meta property="og:url" content="https://www.droplla.com/dropshipping-profit-margin-calculator" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.droplla.com/images/profit-margin-calculator-screenshot.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Free Dropshipping Profit Calculator" />
                <meta name="twitter:description" content="Calculate true profit margins for your dropshipping business with our free tool." />
                <meta name="twitter:image" content="https://www.droplla.com/images/profit-margin-calculator-screenshot.png" />

                {/* Viewport for mobile responsiveness */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>

            </Helmet>
            <Flex
                bg="#edf3f8"
                _dark={{
                    bg: "#3e3e3e",
                }}
                px={{
                    base: 0,
                    lg: 2,
                }}
                pb={2}
                w="full"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    w="full"
                    mt={2}
                    py={{
                        base: "5",
                        lg: "5",
                    }}
                    px={{
                        base: 4,
                        lg: 8,
                    }}
                    bg="white"
                    _dark={{
                        bg: "gray.800",
                    }}
                    rounded="md"
                >
                    <Box
                        maxW="1700px"
                        mx="auto"
                        px={{
                            base: 0,
                            lg: 0,
                        }}
                    >
                        <Box
                            textAlign={{
                                base: "center",
                            }}
                        >
                            <Heading as="h1"
                                _light={{
                                    color: "droplla.500",
                                }}
                                fontWeight="semibold"
                                letterSpacing="wide"
                                fontSize={{
                                    base: "3xl",
                                    lg: "4xl",
                                }}
                                
                            >
                                Dropshipping Profit Calculator
                            </Heading>
                            <Heading as="h2"
                                mt={2}
                                display={{base: "none", md: "block"}}  
                                fontSize={{
                                    base: "lg",
                                    lg: "xl",
                                }}
                                lineHeight="8"
                                fontWeight="extrabold"
                                letterSpacing="tight"
                                _light={{
                                    color: "secondaryGray.900",
                                }}
                            >
                                The Essential Profit Tool for Dropshippers and E-Commerce Entrepreneurs
                            </Heading>
                            <Text
                                mt={2}
                                display={{base: "none", md: "block"}}    
                                fontSize={{
                                    base: "md",
                                    lg: "lg",
                                }}
                                mx={{
                                    lg: "auto",
                                }}
                                color="subtleGray.500"
                                _dark={{
                                    color: "gray.400",
                                }}
                            >
                                Our free calculator delivers deep financial insights for dropshippers and e-commerce entrepreneurs.
                                Uncover true profitability by factoring in your real-world costs.
                            </Text>
                        </Box>

                        <Heading as='h3'
                            mt={10}
                            mb={2}
                            fontSize={{
                                base: "xl",
                                lg: "xl",
                            }}
                            lineHeight="8"
                            fontWeight="bold"
                            letterSpacing="tight"
                            _light={{
                                color: "secondaryGray.900",
                            }}
                        >
                            Profit Margin Calcluator
                        </Heading>
                        <Divider width="100%" borderWidth="3px" borderColor="blue.500"
                            opacity={1} />

                        <Box mt={8} mb="64px">
                            <DropShippingCalculator />
                        </Box>
                    </Box>
                </Box>
            </Flex>
            <Content />
        </>
    );
};

export default SimpleCalculator;
