import React, { useState } from 'react';
import { Flex, Stack, Text, Tooltip, Icon, Select } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const FxPicker = ({ handleFxChange, fontSize="17px"}) => {

    const fxOptions = [
        {
            name: "USD - United States Dollars",
            value: { CurrencyPair: "USD/USD", FxRate: 1, LastUpdated: "2024-10-14" }
        },
        {
            name: "GBP - Great British Pounds",
            value: { CurrencyPair: "USD/GBP", FxRate: 0.77, LastUpdated: "2024-10-14" }
        },
        {
            name: "EUR - Euros",
            value: { CurrencyPair: "USD/EUR", FxRate: 0.92, LastUpdated: "2024-10-14" }
        },
        {
            name: "CAD - Canadian Dollars",
            value: { CurrencyPair: "USD/CAD", FxRate: 1.38, LastUpdated: "2024-10-14" }
        },
        {
            name: "AUD - Australian Dollars",
            value: { CurrencyPair: "USD/AUD", FxRate: 1.49, LastUpdated: "2024-10-14" }
        }
    ];

    const [selectedFx, setSelectedFx] = useState(fxOptions[0].value); // default to first option

    return (
        <Flex width="80%" maxW="100%" height="60px" alignItems="left" marginRight={{ base: "0", lg: "0" }}>
            <Stack direction="column" spacing={0} w="100%">
                <Flex justify="space-between" alignItems="center" marginBottom={1}>
                    <Text color="gray.800" fontSize={fontSize} fontWeight="500" align="left">
                        Currency
                        <Tooltip hasArrow size="xs" label="Select the base currency of your store" placement="top" closeOnClick={false} textAlign="center">
                            <Icon
                                as={InfoOutlineIcon}
                                boxSize={4}
                                ml={2}
                                mb={0.5}
                                color="secondaryGray.800"
                                _hover={{ cursor: 'pointer', color: 'blue.500' }}
                            />
                        </Tooltip>
                    </Text>
                </Flex>
                <Flex color="blue.500" fontSize="12px" align="left">
                    <Select
                        w="100%"
                        fontWeight="500"
                        backgroundColor="white"
                        size="sm"
                        value={JSON.stringify(selectedFx)}
                        onChange={(e) => {
                            const selectedValue = JSON.parse(e.target.value);
                            setSelectedFx(selectedValue);
                            handleFxChange(selectedValue); // Pass to parent
                        }}
                    >
                        {fxOptions.map((option, index) => (
                            <option key={index} value={JSON.stringify(option.value)}>
                                {option.name}
                            </option>
                        ))}
                    </Select>
                </Flex>
            </Stack>
        </Flex>
    );
};

export default FxPicker;
