import React from 'react';
import {
    TableContainer,
    Table,
    TableCaption,
    Tbody,
    Tr,
    Td,
    Text,
    Tooltip,
    Icon,
    Box,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const AdsMetrics = ({
    totalRevenue,
    productAdSpendPerSale,
    categoryRoasMin,
    categoryRoasMax,
    productAiRoasMin,
    productAiRoasMax,
    ROASTarget,
    breakEvenROAS,
    profitPerAcquisition,
    currency
}) => {
    return (
        <Box width="100%"
        borderWidth="1px"
        borderColor={(ROASTarget / 10).toFixed(2) < breakEvenROAS.toFixed(2) ? 'red.600' : 'blue.500'}
        borderRadius="md"
        p={1}>

        <TableContainer>
            <Table variant='striped' size='sm' colorScheme='gray'>
                <TableCaption style={{ marginTop: 0 }}>
                    Key Advertising Metrics</TableCaption>

                <Tbody>
                    <Tr>
                        <Td><Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                            Ad Spend Per Sale
                            <Tooltip hasArrow size='xs' label="The advertising & marketing cost per sinlge product sale. Adjust your ROAS target to set your expected ad spend per sale." placement='top' closeOnClick={false} textAlign="center" >
                                <Icon
                                    as={InfoOutlineIcon}
                                    boxSize={3}
                                    ml={1}
                                    mb={.5}
                                    color="secondaryGray.800"
                                    _hover={{ cursor: 'pointer', color: 'blue.500' }} />
                            </Tooltip>

                        </Text></Td>
                        <Td isNumeric><Text color={totalRevenue < 0 ? "red.500" : "navy.400"} fontSize="13px" fontWeight="500">{Number(productAdSpendPerSale.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}</Text></Td>
                    </Tr>
                    <Tr>
                        <Td><Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                            Profit Per Acquisition
                            <Tooltip hasArrow size='xs' label="The net profit generated from each customer after accounting for all costs, including product expenses, taxes, and ad spend. A higher profit per acquisition indicates a more profitable product and marketing strategy." placement='top' closeOnClick={false} textAlign="center" >
                                <Icon
                                    as={InfoOutlineIcon}
                                    boxSize={3}
                                    ml={1}
                                    mb={.5}
                                    color="secondaryGray.800"
                                    _hover={{ cursor: 'pointer', color: 'blue.500' }} />
                            </Tooltip>

                        </Text></Td>
                        <Td isNumeric><Text color="navy.400" fontSize="13px" fontWeight="500">{Number((profitPerAcquisition).toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}</Text></Td>
                    </Tr>
                    <Tr>
                        <Td><Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                            Break-Even ROAS
                            <Tooltip hasArrow size='xs' label="The minimum Return on Ad Spend (ROAS) required to cover all variable costs. At this point, you're neither making a profit nor a loss. A lower break-even ROAS indicates more efficient marketing." placement='top' closeOnClick={false} textAlign="center" >
                                <Icon
                                    as={InfoOutlineIcon}
                                    boxSize={3}
                                    ml={1}
                                    mb={.5}
                                    color="secondaryGray.800"
                                    _hover={{ cursor: 'pointer', color: 'blue.500' }} />
                            </Tooltip>

                        </Text></Td>
                        <Td isNumeric><Text color="navy.400" fontSize="13px" fontWeight="500">{Number((breakEvenROAS).toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text></Td>
                    </Tr>
                    <Tr>
                        <Td><Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                            ROAS
                            <Tooltip hasArrow size='xs' label={<span>Return on Ad Spend (ROAS) is a metric used to measure the effectiveness of advertising campaigns by comparing the revenue generated from the ads to the cost of running those ads.
                                <br /><br /><b>Ecommerce Benchmark:</b> 4.00 - 8.00
                                <br /><b>Product Category Benchmark:</b> {categoryRoasMin.toFixed(2)} - {categoryRoasMax.toFixed(2)}
                                {productAiRoasMin !== null && productAiRoasMax !== null && (
                                    <>
                                        <br /><b>Product AI Benchmark:</b> {productAiRoasMin.toFixed(2)} - {productAiRoasMax.toFixed(2)}
                                    </>
                                )}
                            </span>} placement='top' closeOnClick={false} textAlign="left" >
                                <Icon
                                    as={InfoOutlineIcon}
                                    boxSize={3}
                                    ml={1}
                                    mb={.5}
                                    color="secondaryGray.800"
                                    _hover={{ cursor: 'pointer', color: 'blue.500' }} />
                            </Tooltip>
                        </Text></Td>
                        <Td isNumeric><Text color={(ROASTarget / 10).toFixed(2) < breakEvenROAS.toFixed(2) ? 'red.600' : 'navy.400'} fontSize="13px" fontWeight="500">{Number((ROASTarget / 10).toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text></Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>

    </Box>
    );
};

export default AdsMetrics;
