import React from 'react';
import {
    TableContainer,
    Table,
    TableCaption,
    Tbody,
    Tr,
    Td,
    Text,
    Tooltip,
    Icon,
    Box,
    Flex,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const ProfitMetrics = ({
    totalRevenue,
    totalCOGS,
    totalGrossProfit,
    grossProfiteErcentage,
    totalInclusiveSalesTax,
    totalExpenses,
    totalProfit,
    profitPercentage,
    currency,
}) => {
    return (
        <Box>
            <Box
      bg={profitPercentage < 0 ? 'red.600' : 'blue.500'}
      borderTopRadius="md"
      p={4}
      mt={{base: 8, xl: 0}}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="xl" fontWeight="800" color="white">
          Net Profit:
        </Text>
        <Text fontSize="xl" fontWeight="800" color="white">
            {Number(totalProfit.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}
        </Text>
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center">
        <Text fontSize="md" fontWeight="800" color="white">
            ( {Number(profitPercentage.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} % )
        </Text>
      </Flex>
    </Box>
        <Box width="100%"
            borderWidth="1px"
            borderColor={profitPercentage < 0 ? 'red.600' : 'blue.500'}
            borderBottomRadius="md"
            marginBottom={2}
            p={1}
            marginTop={{
                base: "0"
            }}>
            <TableContainer>
                <Table variant="striped" size="sm" colorScheme="gray">
                    <TableCaption style={{ marginTop: 0 }}>Key Profit Margin Indicators</TableCaption>
                    <Tbody>
                        <Tr>
                            <Td>
                                <Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                                    Gross Revenue
                                </Text>
                            </Td>
                            <Td isNumeric>
                                <Text color={totalRevenue < 0 ? 'red.500' : 'navy.400'} fontSize="13px" fontWeight="500">
                                    {Number(totalRevenue.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                                Inclusive Sales Tax
                                    <Tooltip
                                        hasArrow
                                        size="xs"
                                        label={
                                            <span>
                                                Inclusive sales taxes, such as VAT or GST, are government-imposed levies included in the price of goods or services sold to customers. Sellers collect these taxes as part of the sale and remit them to tax authorities. Rates vary by country or region and are typically applied at the national, provincial, or state level. eCommerce businesses must comply with regulations by collecting, including, and remitting taxes based on the buyer’s location.
                                            </span>
                                        }
                                        placement="top"
                                        closeOnClick={false}
                                        textAlign="left"
                                    > 
                                        <Icon
                                            as={InfoOutlineIcon}
                                            boxSize={3}
                                            ml={1}
                                            mb={0.5}
                                            color="secondaryGray.800"
                                            _hover={{ cursor: 'pointer', color: 'blue.500' }}
                                        />
                                    </Tooltip>
                                </Text>
                            </Td>
                            <Td isNumeric>
                                <Text color="navy.400" fontSize="13px" fontWeight="500">
                                    {Number(totalInclusiveSalesTax.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                                    COGS
                                    <Tooltip
                                        hasArrow
                                        size="xs"
                                        label={
                                            <span>
                                                Cost of Goods Sold (COGS). Refers to the direct costs associated with purchasing the goods that a company sells during a specific period. COGS includes the cost of shipping expenses and the procurement of the goods sold by the eCommerce business.
                                                <br />
                                                <br />
                                                Estimated Orders * (Wholesale Price + Shipping Cost)
                                            </span>
                                        }
                                        placement="top"
                                        closeOnClick={false}
                                        textAlign="left"
                                    >
                                        <Icon
                                            as={InfoOutlineIcon}
                                            boxSize={3}
                                            ml={1}
                                            mb={0.5}
                                            color="secondaryGray.800"
                                            _hover={{ cursor: 'pointer', color: 'blue.500' }}
                                        />
                                    </Tooltip>
                                </Text>
                            </Td>
                            <Td isNumeric>
                                <Text color={totalCOGS < 0 ? 'red.600' : 'navy.400'} fontSize="13px" fontWeight="500">
                                    {Number(totalCOGS.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                                    Gross Profit
                                </Text>
                            </Td>
                            <Td isNumeric>
                                <Text color={totalGrossProfit < 0 ? 'red.600' : 'navy.400'} fontSize="13px" fontWeight="500">
                                    {Number(totalGrossProfit.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                                    Gross Profit Percentage
                                </Text>
                            </Td>
                            <Td isNumeric>
                                <Text
                                    fontSize="13px"
                                    fontWeight="500"
                                    color='navy.400'
                                >
                                    {Number(grossProfiteErcentage.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text color="secondaryGray.900" fontSize="13px" fontWeight="500" align="left">
                                    Expenses
                                </Text>
                            </Td>
                            <Td isNumeric>
                                <Text color="navy.400" fontSize="13px" fontWeight="500">
                                    {Number(totalExpenses.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}
                                </Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
        </Box>
    );
};

export default ProfitMetrics;
