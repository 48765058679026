import React, { useEffect } from 'react';

const AffiliateImpression = ({ children }) => {
    useEffect(() => {
        const handleAffiliateImpression = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const affiliateId = urlParams.get('ref');
                console.log("affiliateID", affiliateId)

                if (affiliateId) {
                    const response = await fetch(`/api/users/affiliates/impression?affiliateId=${encodeURIComponent(affiliateId)}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    console.log("response", response)
                    if (!response.ok) {
                        console.error('Failed to record affiliate impression:', response.statusText);
                    }
                }
            } catch (error) {
                console.error('Affiliate impression failed:', error);
            }
        };

        handleAffiliateImpression();
    }, []);

    return <>{children}</>;
};

export default AffiliateImpression;
