import { Box, Flex, Stack, Text } from "@chakra-ui/react";

const HeroSection = () => {
  return (
    <Flex
      w="full"
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      justifyContent="center"
    >
      <Flex
        w="full"
        maxW="1700px" // Max width for inner Flex
        bg="#edf3f8"
        _dark={{
          bg: "#3e3e3e",
        }}
        mb={{base: 0, lg: 10}} mt={{base: 0, lg: 6}}
        p={0}

        justifyContent="space-between"
      >
        <Flex flex="1"   justifyContent="center">
          <Box
            py="0px"
            px={{ base: "4", lg: "10" }}
            bg="#edf3f8"
            _dark={{
              bg: "gray.700",
            }}
            justifyContent="center"
          >
            <Stack spacing={2} display={{ base: "none", lg: "block" }}>
              <Text fontSize={36} fontWeight="bold" color="droplla.800" align="center" p={0} lineHeight={1.3}>
                1 Million+
              </Text>
              <Text fontSize={14} fontWeight="bold" color="droplla.800" align="center" lineHeight={1.2}>
                Products and Growing
              </Text>
              <Text fontSize={14} fontWeight="bold" color="secondaryGray.800" align="center" display={{ base: "none", md: "block" }} lineHeight={1.2}>
                Endless Opportunities to Discover Winning Products
              </Text>
            </Stack>
          </Box>
        </Flex>

        <Flex flex="1" mx={2} justifyContent="center">
          <Box
            py="0px"
            px={{ base: "4", md: "10" }}
            bg="#edf3f8"
            _dark={{
              bg: "gray.700",
            }}
          >
            <Stack spacing={2} display={{ base: "none", lg: "block" }} >
              <Text fontSize={36} fontWeight="bold" color="droplla.800" align="center" p={0} lineHeight={1.3}>
                250k+
              </Text>
              <Text fontSize={14} fontWeight="bold" color="droplla.800" align="center" lineHeight={1.2}>
                Products AI Reviewed
              </Text>
              <Text fontSize={14} fontWeight="bold" color="secondaryGray.800" align="center" display={{ base: "none", md: "block" }} lineHeight={1.2}>
                Instantly Available AI Insights to Supercharge Your Store
              </Text>
            </Stack>
          </Box>
        </Flex>

        <Flex flex="1" mx={2} justifyContent="center">
          <Box
            py="0px"
            px={{ base: "4", md: "10" }}
            bg="#edf3f8"
            _dark={{
              bg: "gray.700",
            }}
          >
            <Stack spacing={2} display={{ base: "none", lg: "block" }}>
              <Text fontSize={36} fontWeight="bold" color="droplla.800" align="center" p={0} lineHeight={1.3}>
                50 Million+
              </Text>
              <Text fontSize={14} fontWeight="bold" color="droplla.800" align="center" lineHeight={1.2}>
                Rows of Historical Data
              </Text>
              <Text fontSize={14} fontWeight="bold" color="secondaryGray.800" align="center" display={{ base: "none", md: "block" }} lineHeight={1.2}>
                Unparalleled Analytics Drive Informed Decision-Making
              </Text>
            </Stack>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HeroSection;
