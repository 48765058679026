import React from 'react';
import { Box, Flex, Grid, VStack } from '@chakra-ui/react';
import { FiSettings } from 'react-icons/fi';
import FxPicker from './FxPicker'; // Assuming FxPicker is in the same folder
import PaidAdsToggle from './PaidAdsToggle'; // Assuming PaidAdsToggle is in the same folder
import FreeShippingToggle from './FreeShippingToggle'; // Assuming FreeShippingToggle is in the same folder

const CalcSettings = ({ handleFxChange, setIsPaidAdsActive, setIsShippingDisabled, fontSize="17px"}) => {
    return (
        <Box
            width="100%"
            borderWidth="1px"
            borderColor="blue.500"
            borderRadius="md"
            backgroundColor="gray.100"
            p={4}
            mb={10}
            position="relative"
            display="flex"
            alignItems="center"
        >
            <Flex>
                <Box
                    ml={-10}
                    mr={6}
                    alignItems="center"
                    justifyContent="center"
                    display={{ base: "none", md: "flex" }}
                >
                    <FiSettings size="60px" color="#aeb7d6" style={{ clipPath: 'inset(0 0 0 50%)' }} />
                </Box>

                <Grid
                    templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                    gap={10}
                    width="100%"
                    mb={2}
                    justifyItems="center"
                >
                    <VStack align="left" gap={12} width="100%">
                        <FxPicker handleFxChange={handleFxChange} fontSize={fontSize}/>
                    </VStack>
                    <VStack
                        align="center"
                        gap={12}
                        width="100%"
                        maxW="100%"
                        justify="center"
                    >
                        <Flex direction="row" width="100%" gap={8} justify="center">
                            <PaidAdsToggle setIsPaidAdsActive={setIsPaidAdsActive} fontSize={fontSize}/>
                            <FreeShippingToggle setIsShippingDisabled={setIsShippingDisabled} fontSize={fontSize} />
                        </Flex>
                    </VStack>
                </Grid>
            </Flex>
        </Box>
    );
};

export default CalcSettings;
