import { Box, Divider, Flex, Heading, Text, Link } from "@chakra-ui/react";

const Content = () => {
  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      px={{
        base: 0,
        lg: 2,
      }}
      pb={2}
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        w="full"
        mt={2}
        py={{
          base: "5",
          lg: "5",
        }}
        px={{
          base: 4,
          lg: 8,
        }}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        rounded="md"
      >
        <Box
          maxW="1300px"
          mx="auto"
          px={{
            base: 0,
            lg: 0,
          }}
        >
          <>
            <Heading as='h3'
              mt={10}
              mb={2}
              fontSize={{
                base: "xl",
                lg: "xl",
              }}
              lineHeight="8"
              fontWeight="bold"
              letterSpacing="tight"
              _light={{
                color: "secondaryGray.900",
              }}
            >
              Knowing Your Numbers is Crucial
            </Heading>
            <Divider width="200px" borderWidth="3px" borderColor="blue.500"
              opacity={1} />

            <Box mt={8} mb="64px">
              <Text
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                Understanding your numbers is the key to staying profitable. Costs like product prices, advertising, shipping, and returns can quickly eat away at your margins if you’re not paying attention. Without tracking your profits, you’re just guessing - and guessing is a risky strategy.
                <br /><br />
                If you’re serious about running a successful business, understanding your numbers isn’t optional - it’s essential. It’s the difference between running a sustainable business and one that burns out fast. Effective pricing isn't just about surviving - it's where you'll{' '}
                <Link
                  href="https://www.droplla.com/blog/dropshipping-recommended-markup-analysis"
                  isExternal
                  color="blue.500"
                  _hover={{ textDecoration: "underline" }}
                >
                  boost your profits
                </Link>.
                <br /><br />
                Revenue is vanity, profit is sanity.
              </Text>
            </Box>
          </>

          <>
            <Heading as='h3'
              mt={10}
              mb={2}
              fontSize={{
                base: "xl",
                lg: "xl",
              }}
              lineHeight="8"
              fontWeight="bold"
              letterSpacing="tight"
              _light={{
                color: "secondaryGray.900",
              }}
            >
              What Are Profit Margins?
            </Heading>
            <Divider width="200px" borderWidth="3px" borderColor="blue.500"
              opacity={1} />

            <Box mt={8} mb="64px">
              <Text
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                Net Profit is the money a business keeps after subtracting all the costs involved in running the business. These costs include things like transaction costs, taxes, and other expenses. Net profit is important because it shows how much money the business actually made after covering all expenses.
                <br /><br />
                Net Profit is calculated with the following formula:

              </Text>
              <Box
                bg="subtleGray.50"
                p={4}
                fontSize={{ base: "md", md: "2xl" }}
                fontFamily="Times New Roman"
                borderRadius="md"
                boxShadow="sm"
                mt={6}
                ml={{ base: 1, md: 12 }}
                mr={{ base: 1, md: 12 }}
                textAlign="center"
                overflowX="auto"
                whiteSpace="nowrap"
              >
                Net Profit = Gross Revenue − Inclusive Sales Taxes - COGS − Expenses
              </Box>
              <Text
                mt={6}
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                Where:
                <br /><br />
                - <strong>Gross Revenue</strong> is all the money you earn from selling your products.<br /><br />
                - <strong>Sales Taxes</strong> are extra money collected from customers that goes to the government. These taxes change on revenue threaholds and where you do business.<br /><br />
                - <strong>Cost of Goods Sold (COGS)</strong> is what you spend to make or buy the products you sell. This includes shipping and your purchase costs.<br /><br />
                - <strong>Other Expenses</strong> are all the other costs of running your business. This includes things like fees for using online selling platforms, money spent on ads and marketing, costs for handling returns or damaged items, business insurance, and regular costs like rent, staff salaries, and utility bills.
              </Text>

            </Box>
          </>

          <>
            <Heading as='h3'
              mt={10}
              mb={2}
              fontSize={{
                base: "xl",
                lg: "xl",
              }}
              lineHeight="8"
              fontWeight="bold"
              letterSpacing="tight"
              _light={{
                color: "secondaryGray.900",
              }}
            >
              What Is Gross Profit?
            </Heading>
            <Divider width="200px" borderWidth="3px" borderColor="blue.500"
              opacity={1} />

            <Box mt={8} mb="64px">
              <Text
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                Gross Profit shows how much money a business makes from selling its products after paying for the direct costs of producing those products, but before paying for other expenses like taxes or rent.
                <br /><br />
                To calculate Gross Profit, use this formula:

              </Text>
              <Box
                bg="subtleGray.50"
                p={4}
                fontSize={{ base: "md", md: "2xl" }}
                fontFamily="Times New Roman"
                borderRadius="md"
                boxShadow="sm"
                mt={6}
                ml={{ base: 1, md: 12 }}
                mr={{ base: 1, md: 12 }}
                textAlign="center"
                overflowX="auto"
                whiteSpace="nowrap"
              >
                Gross Profit = Gross Revenue - Inclusive Sales Taxes − COGS
              </Box>
              <Text
                mt={6}
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                Where:
                <br /><br />

                - <strong>Gross Revenue</strong> is all the money you earn from selling your products.<br /><br />
                - <strong>Cost of Goods Sold (COGS)</strong> is what you spend to make or buy the products you sell. This includes shipping and your purchase costs.<br /><br />
              </Text>

            </Box>
          </>

          <>
            <Heading as='h3'
              mt={10}
              mb={2}
              fontSize={{
                base: "xl",
                lg: "xl",
              }}
              lineHeight="8"
              fontWeight="bold"
              letterSpacing="tight"
              _light={{
                color: "secondaryGray.900",
              }}
            >
              Handling Shipping Costs
            </Heading>
            <Divider width="200px" borderWidth="3px" borderColor="blue.500"
              opacity={1} />

            <Box mt={8} mb="64px">
              <Text
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                Shipping costs are the money you spend to send products to your customers. In dropshipping, you might offer free shipping or charge your customers for it. If you offer free shipping, it’s important to include those costs in the price of your products so you don’t lose money. If you charge for shipping, the customer pays for it, so it won’t affect your profits directly. Always keep track of shipping costs to make sure your pricing is right and your business stays profitable.
              </Text>
            </Box>
          </>

          <>
            <Heading as='h3'
              mt={10}
              mb={2}
              fontSize={{
                base: "xl",
                lg: "xl",
              }}
              lineHeight="8"
              fontWeight="bold"
              letterSpacing="tight"
              _light={{
                color: "secondaryGray.900",
              }}
            >
              Returns, Damages, and Chargebacks
            </Heading>
            <Divider width="200px" borderWidth="3px" borderColor="blue.500"
              opacity={1} />

            <Box mt={8} mb="64px">
              <Text
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >

                Sometimes, customers return products, items get damaged, or payments are reversed (chargebacks). In eCommerce, approximately 30% of sales may be affected by these issues, with around 66% of the associated costs being unrecoverable. To safeguard your profits, consider adding a small surcharge to each sale. This extra charge can help cover these potential losses, ensuring you’re not out of pocket. Planning for such challenges will keep your business running smoothly and protect your bottom line.       </Text>
            </Box>
          </>

          <>
            <Heading as='h3'
              mt={10}
              mb={2}
              fontSize={{
                base: "xl",
                lg: "xl",
              }}
              lineHeight="8"
              fontWeight="bold"
              letterSpacing="tight"
              _light={{
                color: "secondaryGray.900",
              }}
            >
              What Is ROAS?
            </Heading>
            <Divider width="200px" borderWidth="3px" borderColor="blue.500"
              opacity={1} />

            <Box mt={8} mb="64px">
              <Text
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                ROAS (Return on Ad Spend) measures how much money a business makes for every dollar spent on advertising. It helps businesses understand whether their advertising efforts are paying off.
                <br /><br />
                To calculate ROAS, use this formula:

              </Text>
              <Box
                bg="subtleGray.50"
                p={4}
                fontSize={{ base: "md", md: "2xl" }}
                fontFamily="Times New Roman"
                borderRadius="md"
                boxShadow="sm"
                mt={6}
                ml={{ base: 1, md: 12 }}
                mr={{ base: 1, md: 12 }}
                textAlign="center"
                overflowX="auto"
                whiteSpace="nowrap"
              >
                <span style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  fontSize: '1em'
                }}>
                  <span style={{ marginRight: '0.5em' }}>ROAS</span>
                  <span style={{ margin: '0 0.3em' }}>=</span>
                  <span style={{
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}>
                    <div style={{
                      borderBottom: '1px solid black',
                      textAlign: 'center',
                      padding: '0 0.2em'
                    }}>
                      Revenue from Ads
                    </div>
                    <div style={{
                      textAlign: 'center',
                      padding: '0 0.2em'
                    }}>
                      Ad Spend
                    </div>
                  </span>
                </span>
              </Box>

            </Box>
          </>
          <>
            <Heading as='h3'
              mt={10}
              mb={2}
              fontSize={{
                base: "xl",
                lg: "xl",
              }}
              lineHeight="8"
              fontWeight="bold"
              letterSpacing="tight"
              _light={{
                color: "secondaryGray.900",
              }}
            >
              What Is Break-Even ROAS?
            </Heading>
            <Divider width="200px" borderWidth="3px" borderColor="blue.500"
              opacity={1} />

            <Box mt={8} mb="64px">
              <Text
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                Break-Even ROAS tells you the minimum revenue you need from advertising to avoid losing money. It helps businesses understand the true financial performance of their ad campaigns.
                <br /><br />
                To calculate Break-Even ROAS, follow these steps:
                <br /><br />
                <strong>Step 1</strong> - Calculate your Contribution Margin using your Variable Costs (COGS + Taxes + Expenses)
              </Text>

              <Box
                bg="subtleGray.50"
                p={4}
                fontSize={{ base: "md", md: "2xl" }}
                fontFamily="Times New Roman"
                borderRadius="md"
                boxShadow="sm"
                mt={6}
                ml={{ base: 1, md: 12 }}
                mr={{ base: 1, md: 12 }}
                textAlign="center"
                overflowX="auto"
                whiteSpace="nowrap"
              >
                Contribution Margin = Total Revenue - Variable Costs
              </Box>

              <Text
                mt={6}
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                <strong>Step 2</strong> - Calculate your Contribution Margin Percentage and divide 100 by it to get your Break-Even

              </Text>
              <Box
                bg="subtleGray.50"
                p={4}
                fontSize={{ base: "md", md: "2xl" }}
                fontFamily="Times New Roman"
                borderRadius="md"
                boxShadow="sm"
                mt={6}
                ml={{ base: 1, md: 12 }}
                mr={{ base: 1, md: 12 }}
                textAlign="center"
                overflowX="auto"
                whiteSpace="nowrap"
              >
                <span style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  fontSize: '1em'
                }}>
                  <span style={{ marginRight: '0.5em' }}>Contribution Margin Percentage</span>
                  <span style={{ margin: '0 0.3em' }}>=</span>
                  <span style={{
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}>
                    <span style={{
                      display: 'inline-flex',
                      alignItems: 'center'
                    }}>
                      <span style={{ margin: '0 0.2em' }}>(</span>
                      <span style={{
                        display: 'inline-block',
                        verticalAlign: 'middle'
                      }}>
                        <div style={{
                          borderBottom: '1px solid black',
                          textAlign: 'center',
                          padding: '0 0.2em'
                        }}>
                          Contribution Margin
                        </div>
                        <div style={{
                          textAlign: 'center',
                          padding: '0 0.2em'
                        }}>
                          Total Revenue
                        </div>
                      </span>
                      <span style={{ margin: '0 0.2em' }}>)</span>
                    </span>
                    <span style={{ margin: '0 0.3em' }}>×</span>
                    <span>100</span>
                  </span>
                </span>
              </Box>
            </Box>
          </>
        </Box>
      </Box>
    </Flex>
  );
};

export default Content;
