import React from 'react';
import { Flex, Stack, Text, Tooltip, Icon, Switch } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const FreeShippingToggle = ({ setIsShippingDisabled, fontSize="17px" }) => {
  return (
    <Flex ml={{base: 6, md: 6}}  maxW="100%" height="60px" alignItems="center" marginRight={{ base: "0", lg: "0" }}>
      <Stack direction="column" spacing={0} w="100%">
        <Flex justify="space-between" alignItems="center" marginBottom={1} >
          <Text color="secondaryGray.900" fontSize={fontSize} fontWeight="500" align="left">
            Include Shipping
            <Tooltip hasArrow size='sm' label="If you offer free shipping, include it as a direct expense factored into your selling price. If you charge for shipping at checkout, the customer covers it, and it won't affect your profit calculations." placement='top' closeOnClick={false} textAlign="center">
              <Icon
                as={InfoOutlineIcon}
                boxSize={4}
                ml={2}
                mb={.5}
                color="secondaryGray.800"
                _hover={{ cursor: 'pointer', color: 'blue.500' }} 
              />
            </Tooltip>
          </Text>
        </Flex>
        <Flex color="blue.500" fontSize={fontSize} align="center" alignItems="center" alignContent="center">
          <Switch 
            id="free-shipping" 
            size='md' 
            onChange={(e) => setIsShippingDisabled(e.target.checked)} 
          />
        </Flex>
      </Stack>
    </Flex>
  );
};

export default FreeShippingToggle;
