import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/css/App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ChakraProvider } from '@chakra-ui/react';
import Landing from './views/home/index';
import Auth from './views/auth/signin';
import Register from './views/auth/signup'
import PrivacyPolicy from './views/legal/privacy-policy';
import TermsOfUse from './views/legal/terms-of-use';
import customTheme from './theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Success from 'views/auth/signup/Success';
import CookiesPolicy from 'views/legal/cookies-policy';
import LandingLayout from 'layouts/landingLayout';
import GAWrapper from './components/analytics/GAWrapper';
import DataProcessing from 'views/legal/data-processing';
import RequestPasswordReset from 'views/auth/resetPassword';
import ResetPassword from 'views/auth/resetPassword/resetPassword';
import RequestPasswordResetSucess from 'views/auth/resetPassword/requestPasswordResetSuccess';
import PasswordResetSuccess from 'views/auth/resetPassword/passwordResetSuccess';
import VerifyEmail from 'views/auth/emailVerification';
import ScrollToTop from 'components/utils/scroll';
import { HelmetProvider } from 'react-helmet-async';
import AffiliateImpression from 'components/affiliate/AffiliateImpressionWrapper';
import SimpleCalculator from 'views/tools/calculator';

const App = () => {

  return (
    
    <ChakraProvider theme={customTheme}>
      <ThemeEditorProvider>
        <Router>
          <AffiliateImpression>
          <GAWrapper>
            <ScrollToTop />
            <Routes>
              <Route path="/sign-up" element={<Register />} />
              <Route path="/sign-up-success" element={<Success />} />
              <Route path="/sign-in" element={<Auth />} />
              <Route path="/request-password-reset" element={<RequestPasswordReset />} />
              <Route path="/request-password-reset-success" element={<RequestPasswordResetSucess />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-password-success" element={<PasswordResetSuccess />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route element={<LandingLayout />}>
                <Route path="/" element={<Landing />} />
                <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/legal/terms-of-use" element={<TermsOfUse />} />
                <Route path="/legal/cookies-policy" element={<CookiesPolicy />} />
                <Route path="/legal/data-processing" element={<DataProcessing />} />
                <Route path="/dropshipping-profit-margin-calculator" element={<SimpleCalculator />} />
                <Route path="*" element={<Landing />} />
              </Route>
            </Routes>
          </GAWrapper>
          </AffiliateImpression>
        </Router>
      </ThemeEditorProvider>
    </ChakraProvider>
  );
};

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
     <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
