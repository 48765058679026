import { Menu, MenuButton, MenuList, MenuItem, Button, Divider, Text, Box, Link } from "@chakra-ui/react";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { BiMath } from "react-icons/bi";
import { useState } from "react";
import React from 'react';

function ToolsMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => setIsOpen(!isOpen);

  return (
    <Menu isOpen={isOpen} onClose={() => setIsOpen(false)} onOpen={handleMenuToggle}>
      <MenuButton
        variant="ghost"
        color="gray.600"
        fontWeight="500"
        as={Button}
        minWidth="auto"
        rightIcon={isOpen ? <FaChevronUp size={12}  /> : <FaChevronDown size={12}  />}
      >
        Tools
      </MenuButton>
      <MenuList>
        {/* Heading with divider */}
        <Box px="3" py="2">
          <Text fontSize="sm" color="gray.600" mb={0}>
            Free Tools
          </Text>
        </Box>
        <Divider ml={4} width="85%"/>

        {/* Menu Item with icon */}
        <MenuItem as={Link} href="/dropshipping-profit-margin-calculator" color="gray.600" fontWeight="500" p={4} >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="1px"
            borderColor="gray.300"
            bg="white"
            rounded="md"
            boxSize="8"
            mr="3"

          >
            <BiMath color="#3182ce" size="20px" />
          </Box>
          <Text color="gray.600" fontSize="md" mb={0}>
    Profit Calculator
  </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default ToolsMenu;
