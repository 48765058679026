import React, { useState, useEffect } from 'react';
import {
  Flex,
  Stack,
  Text,
  Tooltip,
  Icon,
  InputGroup,
  Input,
  InputRightAddon,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react';
import { InfoOutlineIcon, MinusIcon, AddIcon } from '@chakra-ui/icons';

const TouchSlider = ({
  label,
  tooltipText,
  currencySymbol,
  value,
  onChange,
  min = 0,
  max = 100,
  step = 0.1,
  dp = 0,
  multFactor = 1,
  isDisabled = false,
  fontSize="17px"
}) => {
  const [intervalId, setIntervalId] = useState(null);
  const [inputValue, setInputValue] = useState((value / multFactor).toFixed(dp));
  

  const handleMouseDown = (changeBy) => {
    const id = setInterval(() => {
      onChange((prevValue) => Math.min(max, Math.max(min, prevValue + changeBy)));
    }, 200);
    setIntervalId(id);
  };

  const handleMouseUp = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    setInputValue((value / multFactor).toFixed(dp)); 
  }, [value, multFactor, dp]);


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    let newValue = parseFloat(inputValue);
    if (isNaN(newValue)) {
      newValue = min; 
    } else {
      newValue = Math.max((min/multFactor), Math.min((max/multFactor), newValue)); 
    }
    onChange(newValue * multFactor)
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    }
  };

  const labelStyleStart = {
    mt: '2',
    color: "secondaryGray.800",
    fontSize: 'xs',
  };

  const labelStyleEnd = {
    mt: '2',
    ml: '-3',
    color: "secondaryGray.800",
    fontSize: 'xs',
  };

  return (
    <Flex width="100%" maxW="100%" height="60px" alignItems="left" marginRight={{ base: '0', lg: '0' }}>
      <Stack direction="column" spacing={0} w="100%">
        <Flex justify="space-between">
          <Text color="secondaryGray.900" fontSize={fontSize} fontWeight="500" align="left" mb={4}>
            {label}
            {tooltipText && (
              <Tooltip
                hasArrow
                size="xs"
                label={tooltipText}
                placement="top"
                closeOnClick={false}
                textAlign="center"
              >
                <Icon
                  as={InfoOutlineIcon}
                  boxSize={4}
                  ml={2}
                  mb={0.5}
                  color="secondaryGray.800"
                  _hover={{ cursor: 'pointer', color: 'blue.500' }}
                />
              </Tooltip>
            )}
          </Text>

          <InputGroup size="sm" color="blue.500"  maxW="150px">
            <Input
            maxLength={7}
            backgroundColor="gray.100"
              textAlign="right"
              color="blue.500"
              fontWeight="500"
              value={inputValue} 
              onChange={handleInputChange} 
              onBlur={handleInputBlur} 
              onKeyDown={handleInputKeyPress} 
            />
            {currencySymbol && <InputRightAddon size="xs">{currencySymbol}</InputRightAddon>}
          </InputGroup>
        </Flex>
        <Flex align="center">
          <IconButton
            isDisabled={isDisabled}
            aria-label="Decrease"
            icon={<MinusIcon />}
            onClick={() => onChange((prev) => Math.max(min, prev - (step * multFactor)))}
            size="xs"
            variant="ghost"
            color="gray.500"
            _hover={{ color: 'blue.500', borderColor: 'blue.700' }}
            onMouseDown={() => handleMouseDown(-(step * multFactor))}
            onMouseUp={handleMouseUp}
            onTouchStart={() => handleMouseDown(-(step * multFactor))}
            onTouchEnd={handleMouseUp}
          />
          <Slider
            value={value}
            onChange={(val) => {
              onChange(val); // Update the slider value
            }}
            min={min}
            max={max}
            step={step * multFactor}
            isDisabled={isDisabled}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={5} borderColor="blue.500" />
            <SliderMark value={min} fontSize="sm" {...labelStyleStart}>
              {(min / multFactor).toFixed(0)}
            </SliderMark>
            <SliderMark value={max} fontSize="sm" {...labelStyleEnd}>
              {(max / multFactor).toFixed(0)}
            </SliderMark>
          </Slider>
          <IconButton
            isDisabled={isDisabled}
            aria-label="Increase"
            icon={<AddIcon />}
            onClick={() => onChange((prev) => Math.min(max, prev + (step * multFactor)))}
            size="xs"
            variant="ghost"
            color="gray.500"
            _hover={{ color: 'blue.500', borderColor: 'blue.700' }}
            onMouseDown={() => handleMouseDown((step * multFactor))}
            onMouseUp={handleMouseUp}
            onTouchStart={() => handleMouseDown((step * multFactor))}
            onTouchEnd={handleMouseUp}
          />
        </Flex>
      </Stack>
    </Flex>
  );
};

export default TouchSlider;
